export enum FacetType {
  CONSTANT = 'CONSTANT',
  FULLTEXT = 'FULLTEXT',
  FULLTEXTF = 'FULLTEXTF',
  ENUM = 'ENUM',
  ENUM_SINGLE = 'ENUM_SINGLE',
  MULTI_REF = 'MULTI_REF',
  MULTI_REF_EXT = 'MULTI_REF_EXT',
  UNITDATE = 'UNITDATE',

  DAO_ONLY = 'DAO_ONLY',
  RELATED_APUS = 'RELATED_APUS',
}

export enum FacetDisplay {
  ALWAYS = 'ALWAYS',
  DETAIL = 'DETAIL',
}
