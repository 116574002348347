import { Message as M } from './message';

export const FRENCH = {
  [M.ACTIVE_FILTERS]: 'Filtres actives',
  [M.ADD_TO_FILTER]: 'Ajouter au filtre',
  [M.ADVANCED_SEARCH]: 'Recherche avancée',
  [M.ALL_FILTERS]: 'Filtres avancés',
  [M.APP_LOADING_FAILED]: 'Erreur/ faute: on n\'a pas lire l\'application',
  [M.APU_HIDE]: 'Hide',
  [M.APU_SHOW_ALL]: 'Show all',
  [M.ARCH_DESC]: 'Document d\'archives',
  [M.ARCH_DESC_DAO_ONLY]: 'Documents d\'archives seulement avec les documents numérisés',
  [M.ARCHIVE]: 'Archives',
  [M.ARCHIVES]: 'Archives',
  [M.ATTACHMENTS]: 'Annexes',
  [M.BASIC_INFORMATION]: 'Information de base',
  [M.BRIGHTNESS]: 'Brightness',
  [M.CANCEL]: 'Annuler',
  [M.CENTER]: 'Centrer',
  [M.CENTURY]: '{century, selectordinal, one {#er} other {#e} } siècle',
  [M.CONFIRM]: 'Confirmer',
  [M.CONTACT]: 'Contact',
  [M.CONTRAST]: 'Contrast',
  [M.COPY_LINK]: 'Copy link to clipboard',
  [M.COPY_LINK_ERROR]: 'Could not copy link',
  [M.COPY_LINK_SUCCESS]: 'Link copied to clipboard',
  [M.COPY_TEXT]: 'Copy text',
  [M.COPY_TEXT_ERROR]: 'Could not copy text',
  [M.COPY_TEXT_SUCCESS]: 'Text copied to clipboard',
  [M.CITATION_CREATE]: 'Create citation',
  [M.CLOSE]: 'Fermer',
  [M.CLICK_TO_COLLAPSE]: 'Roulez/ enroulez par le clique',
  [M.CLICK_TO_EXPAND]: 'Par le clique déroulez/ dépliez',
  [M.DAO]: 'Seulement avec les documents numérisés',
  [M.DAO_ONLY]: 'Document numérisé',
  [M.DAOS]: 'Documents numérisés',
  [M.DISPLAY]: 'Refléter',
  [M.DOWNLOAD]: 'Retirer',
  [M.DOWNLOAD_ALL]: 'Retirer tout',
  [M.DOWNLOAD_METADATA]: 'Retirer des métadonneés',
  [M.ENTER_THE_RANGE]: 'Indiquer l\'écart/ difference',
  [M.ENTITY]: 'Point d\'accès',
  [M.ERROR_DOWNLOAD_FILE]: 'Erreur/ faute: on n\'a pas rétirer d\'ensemble',
  [M.FAVOURITE_QUERIES]: 'Vous cherchez souvent/ fréquemment',
  [M.FILE]: 'Ensemble',
  [M.FILES_IN_DAO]: 'Ensembles dans DAO',
  [M.FIND_RELATED]: 'Chercher les liaisons',
  [M.FIND_RECORDS_IN_FUND]: 'Find records in fund',
  [M.FINDING_AID]: 'Instrument de recherche',
  [M.FIRST_PAGE]: 'First page',
  [M.FROM]: 'De',
  [M.FULLSCREEN]: 'Fullscreen',
  [M.FULLSCREEN_EXIT]: 'Exit fullscreen',
  [M.FUND]: 'Fonds (d\'archives)',
  [M.HELP]: 'Aide',
  [M.IMAGE_SETTINGS]: 'Image settings',
  [M.INACTIVE]: 'Non active',
  [M.INTRODUCTION]: 'Introduction',
  [M.JUMP_PAGES_FORWARD]: 'Jump {number} pages forward',
  [M.JUMP_PAGES_BACKWARD]: 'Jump {number} pages backward',
  [M.LAST_PAGE]: 'Last page',
  [M.INSTITUTION]: 'Archives',
  [M.LIST_OF_DAO]: 'Liste de DAO',
  [M.LOADING]: 'Loading',
  [M.METADATA]: 'Metadata',
  [M.NEW_FILTER]: 'Filtre nouveau',
  [M.NEWS]: 'Actualités',
  [M.NEXT_PAGE]: 'Page suivante',
  [M.NO_FILES_TO_DISPLAY]: 'Aucun ensemble à l\'infographie',
  [M.NO_ITEMS_FOUND]: 'Aucun poste trouvé. Esseyez changer les critères',
  [M.ONLINE]: 'En ligne',
  [M.ORIGINATORS]: 'Créateur',
  [M.OTHER_DAOS]: 'Documents numérisés suivants',
  [M.OTHER_SOURCES]: 'Other sources',
  [M.PER_PAGE]: 'Nombre àla page',
  [M.PRESERVE_VIEW]: 'Preserve view',
  [M.PREVIOUS_PAGE]: 'Page précédente',
  [M.RELATED_TO]: 'N\'est pas en relation avec',
  [M.RELATIONSHIP_NOT_FOUND]: 'Aucun relation trouvée',
  [M.RELATIONSHIP_TYPE_CONSTRAINT]: 'Limitation du type de relation',
  [M.RELATIONSHIPS]: 'Relations',
  [M.REMOVE_FILTERS]: 'Annuler les filtres',
  [M.ROTATE_LEFT]: 'Rotate left',
  [M.ROTATE_RIGHT]: 'Rotate right',
  [M.SEARCH]: 'Recherche',
  [M.SEARCH_ARCH_DESC]: 'Recherche dans les documents d\'archives',
  [M.SEARCH_ARCH_DESC_DAO_ONLY]:
    'Recherche dans les documents d\'archives numérisés',
  [M.SEARCH_BTN]: 'Chercher',
  [M.SEARCH_ENTITY]: 'Recherche dans les mots d\'ordre et dans les mots clés',
  [M.SEARCH_FINDING_AID]: 'Recherche dans les intruments de rechercche',
  [M.SEARCH_FUND]: 'Recherche dans les fonds d\'archives',
  [M.SEARCH_ORIGINATOR]: 'Recherche dans les créateurs',
  [M.SEARCH_INSTITUTION]: 'Recherche dans les archives',
  [M.SEARCH_NO_FILTER]: 'Recherche sans filtres',
  [M.SEARCH_CHANGE_TYPE]: 'Change search type',
  [M.SHARE_THROUGH_EMAIL]: 'Communication à e-mail',
  [M.SHARE_THROUGH_FACEBOOK]: 'Communication à Facebook',
  [M.SHARE_THROUGH_TWITTER]: 'Communication à Twitter',
  [M.SHOW_LESS]: 'Refléter moins',
  [M.SHOW_MORE]: 'Refléter plus',
  [M.SHOW_NAVIGATOR]: 'Show navigator',
  [M.TREE_SHOW]: 'Show tree',
  [M.TREE_HIDE]: 'Hide tree',
  [M.DESCRIPTION_SHOW]: 'Show description',
  [M.DESCRIPTION_HIDE]: 'Hide description',
  [M.TEXT_ARCH_DESC]: 'Recherche des documents d\'archives et les documents d\'archives numérisés concrets',
  [M.TEXT_ENTITY]: 'Recherche des documents d\'archives à l\'aide des mots d\'ordre et des mots clés',
  [M.TEXT_FINDING_AID]: 'Recherche dans les listes des documents d\'archives',
  [M.TEXT_FUND]: 'Recherche des fonds et des collections d\'archives',
  [M.TO]: 'Á',
  [M.TOO_MANY_RESULTS]: 'Beaucoup des résultats',
  [M.TREE_VIEW_CURRENT_STATE]: 'Vue sur l\'etat actuel complet',
  [M.TRY_REFINING_YOUR_SEARCH_AREA]: 'Essayez spécifier la domaine de recherche',
  [M.URL_TOO_LONG]: 'URL est trop longue, on n\'est pas possible ajouter les filtres nouveaux',
  [M.ZOOM_IN]: 'Approcher',
  [M.ZOOM_OUT]: 'Éloigner',
};
