export enum Message {
  ACTIVE_FILTERS = 'ARON_ACTIVE_FILTERS',
  ADD_TO_FILTER = 'ARON_ADD_TO_FILTER',
  ADVANCED_SEARCH = 'ARON_ADVANCED_SEARCH',
  ALL_FILTERS = 'ARON_ALL_FILTERS',
  APP_LOADING_FAILED = 'ARON_APP_LOADING_FAILED',
  APU_HIDE = 'APU_HIDE',
  APU_SHOW_ALL = 'APU_SHOW_ALL',
  ARCH_DESC = 'ARON_ARCH_DESC',
  ARCH_DESC_DAO_ONLY = 'ARON_ARCH_DESC_DAO_ONLY',
  ARCHIVE = 'ARON_ARCHIVE',
  ARCHIVES = 'ARON_ARCHIVES',
  ATTACHMENTS = 'ARON_ATTACHMENTS',
  BASIC_INFORMATION = 'ARON_BASIC_INFORMATION',
  BRIGHTNESS = 'BRIGHTNESS',
  CANCEL = 'ARON_CANCEL',
  CENTER = 'ARON_CENTER',
  CENTURY = 'CENTURY',
  CITATION_CREATE = 'CITATION_CREATE',
  CLICK_TO_COLLAPSE = 'ARON_CLICK_TO_COLLAPSE',
  CLICK_TO_EXPAND = 'ARON_CLICK_TO_EXPAND',
  CLOSE = 'ARON_CLOSE',
  CONFIRM = 'ARON_CONFIRM',
  CONTACT = 'ARON_CONTACT',
  CONTRAST = 'CONTRAST',
  COPY_TEXT = 'COPY_TEXT',
  COPY_TEXT_SUCCESS = 'COPY_TEXT_SUCCESS',
  COPY_TEXT_ERROR = 'COPY_TEXT_ERROR',
  COPY_LINK = 'COPY_LINK',
  COPY_LINK_SUCCESS = 'COPY_LINK_SUCCESS',
  COPY_LINK_ERROR = 'COPY_LINK_ERROR',
  DAO = 'ARON_DAO',
  DAO_ONLY = 'ARON_DAO_ONLY',
  DAOS = 'ARON_DAOS',
  DISPLAY = 'ARON_DISPLAY',
  DOWNLOAD = 'ARON_DOWNLOAD',
  DOWNLOAD_ALL = 'ARON_DOWNLOAD_ALL',
  DOWNLOAD_METADATA = 'ARON_DOWNLOAD_METADATA',
  ENTER_THE_RANGE = 'ARON_ENTER_THE_RANGE',
  ENTITY = 'ARON_ENTITY',
  ERROR_DOWNLOAD_FILE = 'ARON_ERROR_DOWNLOAD_FILE',
  FAVOURITE_QUERIES = 'ARON_FAVOURITE_QUERIES',
  FILE = 'ARON_FILE',
  FILES_IN_DAO = 'ARON_FILES_IN_DAO',
  FIND_RELATED = 'ARON_FIND_RELATED',
  FIND_RECORDS_IN_FUND = 'FIND_RECORDS_IN_FUND',
  FINDING_AID = 'ARON_FINDING_AID',
  FIRST_PAGE = 'FIRST_PAGE',
  FROM = 'ARON_FROM',
  FULLSCREEN = 'ARON_FULLSCREEN',
  FULLSCREEN_EXIT = 'ARON_FULLSCREEN_EXIT',
  FUND = 'ARON_FUND',
  HELP = 'ARON_HELP',
  IMAGE_SETTINGS = 'IMAGE_SETTINGS',
  INACTIVE = 'ARON_INACTIVE',
  INSTITUTION = 'INSTITUTION',
  INTRODUCTION = 'ARON_INTRODUCTION',
  JUMP_PAGES_FORWARD = 'JUMP_PAGES_FORWARD',
  JUMP_PAGES_BACKWARD = 'JUMP_PAGES_BACKWARD',
  LAST_PAGE = 'LAST_PAGE',
  LIST_OF_DAO = 'ARON_LIST_OF_DAO',
  LOADING = 'LOADING',
  METADATA = 'METADATA',
  NEW_FILTER = 'ARON_NEW_FILTER',
  NEWS = 'ARON_NEWS',
  NEXT_PAGE = 'ARON_NEXT_PAGE',
  NO_FILES_TO_DISPLAY = 'ARON_NO_FILES_TO_DISPLAY',
  NO_ITEMS_FOUND = 'ARON_NO_ITEMS_FOUND',
  ONLINE = 'ARON_ONLINE',
  ORIGINATORS = 'ORIGINATORS',
  OTHER_DAOS = 'ARON_OTHER_DAOS',
  OTHER_SOURCES = 'OTHER_SOURCES',
  PER_PAGE = 'ARON_PER_PAGE',
  PRESERVE_VIEW = 'PRESERVE_VIEW',
  PREVIOUS_PAGE = 'ARON_PREVIOUS_PAGE',
  RELATED_TO = 'ARON_RELATED_TO',
  RELATIONSHIP_NOT_FOUND = 'ARON_RELATIONSHIP_NOT_FOUND',
  RELATIONSHIP_TYPE_CONSTRAINT = 'ARON_RELATIONSHIP_TYPE_CONSTRAINT',
  RELATIONSHIPS = 'ARON_RELATIONSHIPS',
  REMOVE_FILTERS = 'ARON_REMOVE_FILTERS',
  ROTATE_LEFT = 'ROTATE_LEFT',
  ROTATE_RIGHT = 'ROTATE_RIGHT',
  SEARCH = 'ARON_SEARCH',
  SEARCH_ARCH_DESC = 'ARON_SEARCH_ARCH_DESC',
  SEARCH_ARCH_DESC_DAO_ONLY = 'ARON_SEARCH_ARCH_DESC_DAO_ONLY',
  SEARCH_BTN = 'ARON_SEARCH_BTN',
  SEARCH_ENTITY = 'ARON_SEARCH_ENTITY',
  SEARCH_FINDING_AID = 'ARON_SEARCH_FINDING_AID',
  SEARCH_FUND = 'ARON_SEARCH_FUND',
  SEARCH_ORIGINATOR = 'ARON_SEARCH_ORIGINATOR',
  SEARCH_INSTITUTION = 'ARON_SEARCH_INSTITUTION',
  SEARCH_NO_FILTER = 'ARON_SEARCH_NO_FILTER',
  SEARCH_CHANGE_TYPE = 'ARON_SEARCH_CHANGE_TYPE',
  SHARE_THROUGH_EMAIL = 'SHARE_THROUGH_EMAIL',
  SHARE_THROUGH_FACEBOOK = 'SHARE_THROUGH_FACEBOOK',
  SHARE_THROUGH_TWITTER = 'SHARE_THROUGH_TWITTER',
  SHOW_LESS = 'ARON_SHOW_LESS',
  SHOW_MORE = 'ARON_SHOW_MORE',
  SHOW_NAVIGATOR = 'SHOW_NAVIGATOR',
  TREE_SHOW = 'ARON_TREE_SHOW',
  TREE_HIDE = 'ARON_TREE_HIDE',
  DESCRIPTION_SHOW = 'ARON_DESCRIPTION_SHOW',
  DESCRIPTION_HIDE = 'ARON_DESCRIPTION_HIDE',
  TEXT_ARCH_DESC = 'ARON_TEXT_ARCH_DESC',
  TEXT_ENTITY = 'ARON_TEXT_ENTITY',
  TEXT_FINDING_AID = 'ARON_TEXT_FINDING_AID',
  TEXT_FUND = 'ARON_TEXT_FUND',
  TO = 'ARON_TO',
  TOO_MANY_RESULTS = 'ARON_TOO_MANY_RESULTS',
  TREE_VIEW_CURRENT_STATE = 'ARON_TREE_VIEW_CURRENT_STATE',
  TRY_REFINING_YOUR_SEARCH_AREA = 'ARON_TRY_REFINING_YOUR_SEARCH_AREA',
  URL_TOO_LONG = 'ARON_URL_TOO_LONG',
  ZOOM_IN = 'ARON_ZOOM_IN',
  ZOOM_OUT = 'ARON_ZOOM_OUT',
}
