import { Message as M } from './message';

export const CZECH = {
  [M.ACTIVE_FILTERS]: 'Aktivní filtry',
  [M.ADD_TO_FILTER]: 'Přidat k filtru',
  [M.ADVANCED_SEARCH]: 'Pokročilé vyhledávání',
  [M.ALL_FILTERS]: 'Pokročilé filtry',
  [M.ARCH_DESC]: 'Archiválie',
  [M.APP_LOADING_FAILED]: 'Chyba: Nepodařilo se načíst aplikaci.',
  [M.APU_HIDE]: 'Skrýt',
  [M.APU_SHOW_ALL]: 'Zobrazit všechny',
  [M.ARCH_DESC_DAO_ONLY]: 'Archiválie - pouze s digitalizáty',
  [M.ARCHIVE]: 'Archiv',
  [M.ARCHIVES]: 'Archivy',
  [M.ATTACHMENTS]: 'Přílohy',
  [M.BASIC_INFORMATION]: 'Základní informace',
  [M.BRIGHTNESS]: 'Jas',
  [M.CANCEL]: 'Zrušit',
  [M.CENTER]: 'Vycentrovat',
  [M.CENTURY]: "{century}. století",
  [M.CONFIRM]: 'Potvrdit',
  [M.CONTACT]: 'Kontakt',
  [M.CONTRAST]: 'Kontrast',
  [M.COPY_LINK]: 'Zkopírovat odkaz do schránky',
  [M.COPY_LINK_ERROR]: 'Odkaz se nepodařilo zkopírovat',
  [M.COPY_LINK_SUCCESS]: 'Odkaz zkopírován do schránky',
  [M.COPY_TEXT]: 'Zkopírovat text',
  [M.COPY_TEXT_ERROR]: 'Text se nepodařilo zkopírovat',
  [M.COPY_TEXT_SUCCESS]: 'Text zkopírován do schránky',
  [M.CITATION_CREATE]: 'Vytvořit citaci',
  [M.CLOSE]: 'Zavřít',
  [M.CLICK_TO_COLLAPSE]: 'Kliknutím sbalte',
  [M.CLICK_TO_EXPAND]: 'Kliknutím rozbalte',
  [M.DAO_ONLY]: 'Pouze s digitalizáty',
  [M.DAO]: 'digitalizát',
  [M.DAOS]: 'Digitalizáty',
  [M.DISPLAY]: 'Zobrazit',
  [M.DOWNLOAD]: 'Stáhnout',
  [M.DOWNLOAD_ALL]: 'Stáhnout vše',
  [M.DOWNLOAD_METADATA]: 'Stáhnout metadata',
  [M.ENTER_THE_RANGE]: 'Zadat rozpětí',
  [M.ENTITY]: 'Přístupové body',
  [M.ERROR_DOWNLOAD_FILE]: 'Chyba: Nepodařilo se stáhnout soubor.',
  [M.FAVOURITE_QUERIES]: 'Často hledáte',
  [M.FILE]: 'soubor',
  [M.FILES_IN_DAO]: 'Soubory v DAO',
  [M.FIND_RELATED]: 'Najít související',
  [M.FIND_RECORDS_IN_FUND]: 'Vyhledat archiválie v archivním souboru',
  [M.FINDING_AID]: 'Archivní pomůcky',
  [M.FIRST_PAGE]: 'První strana',
  [M.FROM]: 'Od',
  [M.FULLSCREEN]: 'Celá obrazovka',
  [M.FULLSCREEN_EXIT]: 'Ukončit celou obrazovku',
  [M.FUND]: 'Archivní soubory',
  [M.FROM]: 'Od',
  [M.HELP]: 'Nápověda',
  [M.IMAGE_SETTINGS]: 'Nastavení zobrazení',
  [M.INACTIVE]: 'neaktivní',
  [M.INSTITUTION]: 'Archivy',
  [M.INTRODUCTION]: 'Úvod',
  [M.JUMP_PAGES_FORWARD]: 'O {number} stran vpřed',
  [M.JUMP_PAGES_BACKWARD]: 'O {number} stran zpět',
  [M.LAST_PAGE]: 'Poslední strana',
  [M.LIST_OF_DAO]: 'Seznam DAO',
  [M.LOADING]: 'Načítání',
  [M.METADATA]: 'Metadata',
  [M.NEW_FILTER]: 'Nový filtr',
  [M.NEWS]: 'Aktuality',
  [M.NEXT_PAGE]: 'Další strana',
  [M.NO_FILES_TO_DISPLAY]: 'Žádné soubory k zobrazení',
  [M.NO_ITEMS_FOUND]:
    'Žádné položky nenalezeny. Zkuste změnit zadaná kritéria.',
  [M.ONLINE]: 'Online',
  [M.ORIGINATORS]: 'Původci',
  [M.OTHER_DAOS]: 'Další digitalizáty',
  [M.OTHER_SOURCES]: 'Jiné zdroje',
  [M.PER_PAGE]: 'Počet na stránce',
  [M.PRESERVE_VIEW]: 'Zachovat pohled',
  [M.PREVIOUS_PAGE]: 'Předchozí strana',
  [M.RELATED_TO]: 'Související s',
  [M.RELATIONSHIP_NOT_FOUND]: 'Žádný vztah nebyl nalezen',
  [M.RELATIONSHIP_TYPE_CONSTRAINT]: 'Omezení typu vztahu',
  [M.RELATIONSHIPS]: 'Vztahy',
  [M.REMOVE_FILTERS]: 'Zrušit filtry',
  [M.ROTATE_LEFT]: 'Otočit vlevo',
  [M.ROTATE_RIGHT]: 'Otočit vpravo',
  [M.SEARCH]: 'Vyhledávání',
  [M.SEARCH_ARCH_DESC]: 'Vyhledávání v archiváliích',
  [M.SEARCH_ARCH_DESC_DAO_ONLY]: 'Vyhledávání v archiváliích s digitalizáty',
  [M.SEARCH_BTN]: 'Hledat',
  [M.SEARCH_ENTITY]: 'Vyhledávání v rejstříkových heslech a klíčových slovech',
  [M.SEARCH_FINDING_AID]: 'Vyhledávání v archivních pomůckách',
  [M.SEARCH_FUND]: 'Vyhledávání v archivních souborech',
  [M.SEARCH_ORIGINATOR]: 'Vyhledávání v původcích',
  [M.SEARCH_INSTITUTION]: 'Vyhledávání v archivech',
  [M.SEARCH_NO_FILTER]: 'Vyhledávání bez filtru',
  [M.SEARCH_CHANGE_TYPE]: 'Změnit typ vyhledávání',
  [M.SHARE_THROUGH_EMAIL]: 'Sdílet prostřednictvím e-mailu',
  [M.SHARE_THROUGH_FACEBOOK]: 'Sdílet na Facebook',
  [M.SHARE_THROUGH_TWITTER]: 'Sdílet na Twitter',
  [M.SHOW_LESS]: 'Zobrazit méně',
  [M.SHOW_MORE]: 'Zobrazit více',
  [M.SHOW_NAVIGATOR]: 'Zobrazit navigaci',
  [M.TREE_SHOW]: 'Zobrazit strom',
  [M.TREE_HIDE]: 'Skrýt strom',
  [M.DESCRIPTION_SHOW]: 'Zobrazit popis',
  [M.DESCRIPTION_HIDE]: 'Skrýt popis',
  [M.TEXT_ARCH_DESC]: 'Vyhledávání konkrétních archiválií a digitalizátů',
  [M.TEXT_ENTITY]:
    'Vyhledávání archiválií pomocí rejstříkových hesel a klíčových slov',
  [M.TEXT_FINDING_AID]: 'Vyhledávání v seznamech archiválií',
  [M.TEXT_FUND]: 'Vyhledávání archivních fondů a sbírek',
  [M.TO]: 'Do',
  [M.TOO_MANY_RESULTS]: 'Příliš mnoho výsledků?',
  [M.TREE_VIEW_CURRENT_STATE]: 'Pohled na celý AS (aktuální stav)',
  [M.TRY_REFINING_YOUR_SEARCH_AREA]: 'Zkuste upřesnit oblast vyhledávání',
  [M.URL_TOO_LONG]: 'URL je příliš dlouhá, nelze přidat další filtry.',
  [M.ZOOM_IN]: 'Přiblížit',
  [M.ZOOM_OUT]: 'Oddálit',
};
