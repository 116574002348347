import { Message as M } from './message';

export const GERMAN = {
  [M.ACTIVE_FILTERS]: 'Aktive Filter',
  [M.ADD_TO_FILTER]: 'Zum Filter hinzufügen',
  [M.ADVANCED_SEARCH]: 'Erweiterte Suche',
  [M.ALL_FILTERS]: 'Erweiterte Filter',
  [M.APP_LOADING_FAILED]: 'Fehler: Die Anwendung konnte nicht geladen werden.',
  [M.APU_HIDE]: 'Hide',
  [M.APU_SHOW_ALL]: 'Show all',
  [M.ARCH_DESC]: 'Archivalien',
  [M.ARCH_DESC_DAO_ONLY]: 'Archivalien - nur mit Digitalobjekten',
  [M.ARCHIVE]: 'Archiv',
  [M.ARCHIVES]: 'Archive',
  [M.ATTACHMENTS]: 'Anhänge',
  [M.BASIC_INFORMATION]: 'Grundinformation',
  [M.BRIGHTNESS]: 'Brightness',
  [M.CANCEL]: 'Löschen',
  [M.CENTER]: 'Center',
  [M.CENTURY]: "{century}. Jahrhundert",
  [M.CONFIRM]: 'Bestätigen',
  [M.CONTACT]: 'Kontakt',
  [M.CONTRAST]: 'Contrast',
  [M.COPY_LINK]: 'Copy link to clipboard',
  [M.COPY_LINK_ERROR]: 'Could not copy link',
  [M.COPY_LINK_SUCCESS]: 'Link copied to clipboard',
  [M.COPY_TEXT]: 'Copy text',
  [M.COPY_TEXT_ERROR]: 'Could not copy text',
  [M.COPY_TEXT_SUCCESS]: 'Text copied to clipboard',
  [M.CITATION_CREATE]: 'Create citation',
  [M.CLOSE]: 'Schließen',
  [M.CLICK_TO_COLLAPSE]: 'Zum Zusammenklappen klicken',
  [M.CLICK_TO_EXPAND]: 'Klicken Sie zum Erweitern',
  [M.DAO]: 'Digitales Objekt',
  [M.DAO_ONLY]: 'Nur mit Digitalobjekten',
  [M.DAOS]: 'Digitale Objekte',
  [M.DISPLAY]: 'Anzeige',
  [M.DOWNLOAD]: 'Herunterladen',
  [M.DOWNLOAD_ALL]: 'Alles herunterladen',
  [M.DOWNLOAD_METADATA]: 'Metadaten herunterladen',
  [M.ENTER_THE_RANGE]: 'Margen eingeben',
  [M.ENTITY]: 'Zugriffspunkte',
  [M.ERROR_DOWNLOAD_FILE]: 'Fehler: Datei konnte nicht heruntergeladen werden.',
  [M.FAVOURITE_QUERIES]: 'Oft gesucht',
  [M.FILE]: 'Datei',
  [M.FILES_IN_DAO]: 'Dateien in DAO',
  [M.FIND_RELATED]: 'Verwandte finden',
  [M.FIND_RECORDS_IN_FUND]: 'Find records in fund',
  [M.FINDING_AID]: 'Findhilfsmittel',
  [M.FIRST_PAGE]: 'First page',
  [M.FROM]: 'Von',
  [M.FULLSCREEN]: 'Fullscreen',
  [M.FULLSCREEN_EXIT]: 'Exit fullscreen',
  [M.FUND]: 'Archivbestände',
  [M.HELP]: 'Hilfe',
  [M.IMAGE_SETTINGS]: 'Image settings',
  [M.INACTIVE]: 'Inaktiv',
  [M.INTRODUCTION]: 'Startseite',
  [M.JUMP_PAGES_FORWARD]: 'Jump {number} pages forward',
  [M.JUMP_PAGES_BACKWARD]: 'Jump {number} pages backward',
  [M.LAST_PAGE]: 'Last page',
  [M.INSTITUTION]: 'Archiv',
  [M.LIST_OF_DAO]: 'Liste der DAOs',
  [M.LOADING]: 'Loading',
  [M.METADATA]: 'Metadata',
  [M.NEW_FILTER]: 'Neuer Filter',
  [M.NEWS]: 'Aktuelles',
  [M.NEXT_PAGE]: 'Nächste Seite',
  [M.NO_FILES_TO_DISPLAY]: 'Keine Dateien zum Anzeigen',
  [M.NO_ITEMS_FOUND]: 'Keine Artikel gefunden. Versuchen Sie, Ihre Kriterien zu ändern.',
  [M.ONLINE]: 'Online',
  [M.ORIGINATORS]: 'Provenienzstelle',
  [M.OTHER_DAOS]: 'Mehr Digitale Objekte',
  [M.OTHER_SOURCES]: 'Other sources',
  [M.PER_PAGE]: 'Zahl auf der Seite',
  [M.PRESERVE_VIEW]: 'Preserve view',
  [M.PREVIOUS_PAGE]: 'Vorherige Seite',
  [M.RELATED_TO]: 'Zugehörige ...',
  [M.RELATIONSHIP_NOT_FOUND]: 'Keine Beziehung gefunden',
  [M.RELATIONSHIP_TYPE_CONSTRAINT]: 'Einschränkungen der Beziehungsart',
  [M.RELATIONSHIPS]: 'Beziehungen',
  [M.REMOVE_FILTERS]: 'Filter entfernen',
  [M.ROTATE_LEFT]: 'Rotate left',
  [M.ROTATE_RIGHT]: 'Rotate right',
  [M.SEARCH]: 'Suche',
  [M.SEARCH_ARCH_DESC]: 'Suche im Archivbestand',
  [M.SEARCH_ARCH_DESC_DAO_ONLY]:
    'Nur in Archivbestände mit Digitalobjekten suchen ',
  [M.SEARCH_BTN]: 'Suchen',
  [M.SEARCH_ENTITY]: 'Suche in Indexeinträgen und Schlüsselwörtern',
  [M.SEARCH_FINDING_AID]: 'Suche in Findhilfsmitteln',
  [M.SEARCH_FUND]: 'Suche in Archivbestände',
  [M.SEARCH_ORIGINATOR]: 'Suche in Provenienzstellen',
  [M.SEARCH_INSTITUTION]: 'Suche in Archiven',
  [M.SEARCH_NO_FILTER]: 'Ohne Filter suchen',
  [M.SEARCH_CHANGE_TYPE]: 'Change search type',
  [M.SHARE_THROUGH_EMAIL]: 'Per E-mail teilen',
  [M.SHARE_THROUGH_FACEBOOK]: 'Auf Facebook teilen',
  [M.SHARE_THROUGH_TWITTER]: 'Auf Twitter teilen',
  [M.SHOW_LESS]: 'Weniger anzeigen',
  [M.SHOW_MORE]: 'Mehr anzeigen',
  [M.SHOW_NAVIGATOR]: 'Show navigator',
  [M.TREE_SHOW]: 'Show tree',
  [M.TREE_HIDE]: 'Hide tree',
  [M.DESCRIPTION_SHOW]: 'Show description',
  [M.DESCRIPTION_HIDE]: 'Hide description',
  [M.TEXT_ARCH_DESC]: 'Suche nach bestimmten Archivbestsanden und Digitalobjekten',
  [M.TEXT_ENTITY]: 'Suche nach Archivbestand anhand von Indexeinträgen und Stichworten',
  [M.TEXT_FINDING_AID]: 'Suche in Verzeichnissen von Archivalien',
  [M.TEXT_FUND]: 'Suche nach Archivbestände und Sammlungen',
  [M.TO]: 'Bis',
  [M.TOO_MANY_RESULTS]: 'Zu viele Ergebnisse?',
  [M.TREE_VIEW_CURRENT_STATE]: 'Der gesamten Archivbestand ansehen (aktueller Stand)',
  [M.TRY_REFINING_YOUR_SEARCH_AREA]: 'Versuchen Sie, Ihr Suchgebiet zu verfeinern',
  [M.URL_TOO_LONG]: 'Die URL ist zu lang, es können keine weiteren Filter hinzugefügt werden.',
  [M.ZOOM_IN]: 'Vergrößern',
  [M.ZOOM_OUT]: 'Verkleinern',
};
