import { Message as M } from './message';

export const ENGLISH = {
  [M.ACTIVE_FILTERS]: 'Active filters',
  [M.ADD_TO_FILTER]: 'Add to filter',
  [M.ADVANCED_SEARCH]: 'Advanced search',
  [M.ALL_FILTERS]: 'All filters',
  [M.APP_LOADING_FAILED]: 'Error: Failed to load app.',
  [M.APU_HIDE]: 'Hide',
  [M.APU_SHOW_ALL]: 'Show all',
  [M.ARCH_DESC]: 'Archival record',
  [M.ARCH_DESC_DAO_ONLY]: 'Archival record - digital objects only',
  [M.ARCHIVE]: 'Archive',
  [M.ARCHIVES]: 'Archives',
  [M.ATTACHMENTS]: 'Attachments',
  [M.BASIC_INFORMATION]: 'Basic information',
  [M.BRIGHTNESS]: 'Brightness',
  [M.CANCEL]: 'Cancel',
  [M.CENTER]: 'Center',
  [M.CENTURY]: "{century, selectordinal, one {#st} two {#nd} few {#rd} other {#th} } century",
  [M.CONFIRM]: 'Confirm',
  [M.CONTACT]: 'Contact',
  [M.CONTRAST]: 'Contrast',
  [M.COPY_LINK]: 'Copy link to clipboard',
  [M.COPY_LINK_ERROR]: 'Could not copy link',
  [M.COPY_LINK_SUCCESS]: 'Link copied to clipboard',
  [M.COPY_TEXT]: 'Copy text',
  [M.COPY_TEXT_ERROR]: 'Could not copy text',
  [M.COPY_TEXT_SUCCESS]: 'Text copied to clipboard',
  [M.CITATION_CREATE]: 'Create citation',
  [M.CLOSE]: 'Close',
  [M.CLICK_TO_COLLAPSE]: 'Click to collapse',
  [M.CLICK_TO_EXPAND]: 'Click to expand',
  [M.DAO]: 'Digital object',
  [M.DAO_ONLY]: 'Digital objects only',
  [M.DAOS]: 'Digital objects',
  [M.DISPLAY]: 'Display',
  [M.DOWNLOAD]: 'Download',
  [M.DOWNLOAD_ALL]: 'Download all',
  [M.DOWNLOAD_METADATA]: 'Download metadata',
  [M.ENTER_THE_RANGE]: 'Enter the range',
  [M.ENTITY]: 'Access points',
  [M.ERROR_DOWNLOAD_FILE]: 'Error: Failed to download file.',
  [M.FAVOURITE_QUERIES]: 'Favourite queries',
  [M.FILE]: 'File',
  [M.FILES_IN_DAO]: 'Files in DAO',
  [M.FIND_RELATED]: 'Find related',
  [M.FIND_RECORDS_IN_FUND]: 'Find records in fund',
  [M.FINDING_AID]: 'Finding aids',
  [M.FIRST_PAGE]: 'First page',
  [M.FROM]: 'From',
  [M.FULLSCREEN]: 'Fullscreen',
  [M.FULLSCREEN_EXIT]: 'Exit fullscreen',
  [M.FUND]: 'Record group / Archival fond / Collection',
  [M.HELP]: 'Help',
  [M.IMAGE_SETTINGS]: 'Image settings',
  [M.INACTIVE]: 'inactive',
  [M.INTRODUCTION]: 'Introduction',
  [M.JUMP_PAGES_FORWARD]: 'Jump {number} pages forward',
  [M.JUMP_PAGES_BACKWARD]: 'Jump {number} pages backward',
  [M.LAST_PAGE]: 'Last page',
  [M.INSTITUTION]: 'Archives',
  [M.LIST_OF_DAO]: 'List of DAO',
  [M.LOADING]: 'Loading',
  [M.METADATA]: 'Metadata',
  [M.NEW_FILTER]: 'New filter',
  [M.NEWS]: 'News',
  [M.NEXT_PAGE]: 'Next page',
  [M.NO_FILES_TO_DISPLAY]: 'No files to display',
  [M.NO_ITEMS_FOUND]: 'No items found. Try changing the entered criteria.',
  [M.ONLINE]: 'Online',
  [M.ORIGINATORS]: 'Creators',
  [M.OTHER_DAOS]: 'Other digital objects',
  [M.OTHER_SOURCES]: 'Other sources',
  [M.PER_PAGE]: 'Per page',
  [M.PRESERVE_VIEW]: 'Preserve view',
  [M.PREVIOUS_PAGE]: 'Previous page',
  [M.RELATED_TO]: 'Related to',
  [M.RELATIONSHIP_NOT_FOUND]: 'Relationships not found',
  [M.RELATIONSHIP_TYPE_CONSTRAINT]: 'Relationship type constraint',
  [M.RELATIONSHIPS]: 'Relationships',
  [M.REMOVE_FILTERS]: 'Remove filters',
  [M.ROTATE_LEFT]: 'Rotate left',
  [M.ROTATE_RIGHT]: 'Rotate right',
  [M.SEARCH]: 'Search',
  [M.SEARCH_ARCH_DESC]: 'Search in archival records',
  [M.SEARCH_ARCH_DESC_DAO_ONLY]:
    'Search in archival materials with digital objects only',
  [M.SEARCH_BTN]: 'Search',
  [M.SEARCH_ENTITY]: 'Search in keywords',
  [M.SEARCH_FINDING_AID]: 'Search in finding aids',
  [M.SEARCH_FUND]: 'Search in archive groups / record groups / archival fonds',
  [M.SEARCH_ORIGINATOR]: 'Search in creators',
  [M.SEARCH_INSTITUTION]: 'Search in archives',
  [M.SEARCH_NO_FILTER]: 'Search without filter',
  [M.SEARCH_CHANGE_TYPE]: 'Change search type',
  [M.SHARE_THROUGH_EMAIL]: 'Share through e-mail',
  [M.SHARE_THROUGH_FACEBOOK]: 'Share on Facebook',
  [M.SHARE_THROUGH_TWITTER]: 'Share on Twitter',
  [M.SHOW_LESS]: 'Show less',
  [M.SHOW_MORE]: 'Show more',
  [M.SHOW_NAVIGATOR]: 'Show navigator',
  [M.TREE_SHOW]: 'Show tree',
  [M.TREE_HIDE]: 'Hide tree',
  [M.DESCRIPTION_SHOW]: 'Show description',
  [M.DESCRIPTION_HIDE]: 'Hide description',
  [M.TEXT_ARCH_DESC]: 'Search for specific archive records and digital objects',
  [M.TEXT_ENTITY]: 'Search archives using keywords',
  [M.TEXT_FINDING_AID]: 'Search in archival lists',
  [M.TEXT_FUND]: 'Searching for archival fonds / archive groups',
  [M.TO]: 'To',
  [M.TOO_MANY_RESULTS]: 'Too many results?',
  [M.TREE_VIEW_CURRENT_STATE]: 'View of the whole archive group (current state)',
  [M.TRY_REFINING_YOUR_SEARCH_AREA]: 'Try refining your search area',
  [M.URL_TOO_LONG]: 'URL is too long, no more filters can be added.',
  [M.ZOOM_IN]: 'Zoom in',
  [M.ZOOM_OUT]: 'Zoom out',
};
